<template>
  <div class="">
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong>
          <h3>Roles</h3>
        </strong>
        <hwa-button
          v-if="$can('create-roles', 'hwa')"
          icon="PlusIcon"
          label="Create Role"
          @onClick="openRoleModal()"
        />
      </div>

      <div
        v-if="$can('view-roles', 'hwa')"
        class="row"
      >
        <div
          v-for="(item, index) in roles"
          :key="index"
          class="col-md-2 mb-2"
        >
          <div

            class="role-card-view"
            @click="showEditModal(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </b-card>
    <validation-observer
      ref="roleForm"
      v-slot="{invalid}"
    >
      <hwa-modal
        :title="`${updateRoleMode?'Update':'Create'} Role`"
        :show="roleModalOpened"
        @onClose="closeRoleModal()"
        :hide-footer="!$can('update-roles','hwa')"
      >
        <hwa-input
          v-model="role_name"
          name="name"
          placeholder="Enter role name"
          rules="required"
          :immediate="false"
        />
        <div v-if="updateRoleMode">
          <hwa-button
            v-if="$can('view-permissions', 'hwa')"
            icon="LockIcon"
            :icon-only="false"
            variant="warning"
            label="Assign Permissions"
            :disabled="isSystemRole(role_data.role_type)"
            @onClick="showPermissionModal(role_data)"
          />
        </div>

        <template slot="modal-actions">
          <div style="display: flex;justify-content: space-between;">
            <hwa-button
              variant=""
              icon="XIcon"
              label="Cancel"
              @onClick="closeRoleModal()"
            />
            <div v-if="updateRoleMode">
              <hwa-button
                v-if="$can('delete-roles', 'hwa')"
                icon="TrashIcon"
                :icon-only="false"
                variant="danger"
                label="Delete"
                class="mr-1"
                @onClick="showConfirm(role_data)"
                :disabled="isSystemRole(role_data.role_type)"
              />
            </div>
            <hwa-button
              v-if="$can('create-roles', 'hwa') && !updateRoleMode"
              :label="'Create'"
              :disabled="invalid"
              :loading="roleLoading"
              loading-text="Loading..."
              @onClick="createRole()"
            />
            <hwa-button
            v-if="$can('update-roles', 'hwa') && updateRoleMode"
              :label="'Update'"
              :disabled="invalid || (isSystemRole(role_data.role_type) && !isManager(role_data.name))"
              :loading="roleLoading"
              loading-text="Loading..."
              @onClick="updateRole()"
            />
          </div>
        </template>
      </hwa-modal>
    </validation-observer>
    <validation-observer
      ref="roleForm"
      v-slot="{invalid}"
    >
      <hwa-modal
        title="Assign Permissions"
        size="md"
        :show="permissionsModalOpened"
        @onClose="closePermissionModal()"
        :hide-footer="!$can('assign-permissions-to-roles','hwa')"
      >
        <div class="pa-2">
          <div class="row">
            <div class="col-12 mb-1">
              <b-form-checkbox v-model="allSelected">
                <strong>SELECT ALL</strong>
                </b-form-checkbox>
            </div>
          </div>
          <b-form-group label="">
            <b-form-checkbox-group
              v-model="selectedPermissions"
              text-field="name"
              value-field="id"
              :options="permissions"
              stacked
            />
          </b-form-group>
        </div>
        <template slot="modal-actions">
          <b-row class="float-right">
            <hwa-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closePermissionModal()"
            />
            <hwa-button
              label="Save"
              :disabled="invalid"
              :loading="permissionsLoading"
              loading-text="Loading..."
              @onClick="assignPermissions()"
            />
          </b-row>
        </template>
      </hwa-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BTable, BCard, BFormGroup, BFormCheckboxGroup, BRow,BFormCheckbox
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import { ValidationObserver } from 'vee-validate'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaModal from '@/layouts/components/HwaModal.vue'
import confirm from '@/mixins/confirm'

export default {
  components: {
    HwaModal,
    HwaInput,
    HwaButton,
    BTable,
    BCard,
    ValidationObserver,
    BFormGroup,
    BFormCheckboxGroup,
    BRow,
    BFormCheckbox
  },
  mixins: [showToast, confirm],
  data() {
    return {
      fields: [{key:'name',label:"Name"}, 'action'],
      roles: [],
      role_name: '',
      role_id: null,
      roleLoading: false,
      updateRoleMode: false,
      roleModalOpened: false,
      permissions: [],
      permissionsModalOpened: false,
      selectedPermissions: [],
      permissionsLoading: false,
      role_data: {},
      allSelected: false,
    }
  },
  mounted() {
    this.getRoles()
    this.getPermissions()
  },
  watch:{
    allPermissionsSelected(val){
      this.allSelected = val
    },
    allSelected(val){
      if(val){
       this.selectedPermissions = this.permissions.map((p)=>p.id)
      }else{
        if(this.allPermissionsSelected){
          this.selectedPermissions = []
        }
      
      }
    }
  },
  computed:{
    allPermissionsSelected(){
      return this.selectedPermissions.length===this.permissions.length
    }
  },
  methods: {
    isSystemRole(type) {
      return type === 'system'
    },
    isManager(name) {
      const roles = this.$store.getters['auth/roles']
      return roles?.includes('Manager') && name !=='Manager'
    },
    showConfirm(role) {
      this.confirmAction().then(res => {
        if (res) {
          this.deleteRole(role.id)
        }
      })
    },
    showEditModal(role) {
      this.roleModalOpened = true
      this.updateRoleMode = true
      this.role_name = role.name
      this.role_id = role.id
      this.role_data = role
    },
    openRoleModal() {
      this.updateRoleMode = false
      this.roleModalOpened = true
      this.role_name = ''
    },
    closeRoleModal() {
      this.roleModalOpened = false
    },
    updateRole() {
      if (this.role_id) {
        this.roleLoading = true
        this.$http.put('/api/role/roles', { id: this.role_id, name: this.role_name })
          .then(res => {
            this.roleLoading = false
            this.roles = this.roles.map(t => {
              if (t.id === res.data.id) {
                return res.data
              }
              return t
            })
            this.closeRoleModal()
            this.showSuccess('Role updated successfully')
          })
          .catch(err => {
            this.roleLoading = false
            this.showError('Failed to retrieve roles')
          })
      } else {
        this.showInfo('Select a role')
      }
    },
    createRole() {
      this.roleLoading = true
      this.$http.post('/api/role/roles', { name: this.role_name })
        .then(res => {
          this.roleLoading = false
          this.roles = [...this.roles,res.data]
          this.closeRoleModal()
          this.showSuccess('Role created successfully')
        })
        .catch(err => {
          this.roleLoading = false
          this.showError('Failed to retrieve roles')
        })
    },
    getRoles() {
      this.$http.get('/api/role/roles')
        .then(res => {
          this.roles = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve roles')
        })
    },
    getPermissions() {
      this.$http.get('/api/role/permissions')
        .then(res => {
          this.permissions = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve permissions')
        })
    },
    showPermissionModal(role) {
      this.role_id = role.id
      this.permissionsModalOpened = true
      this.selectedPermissions = role.permissions.map(p => p.id)
    },
    closePermissionModal() {
      this.permissionsModalOpened = false
    },
    assignPermissions() {
      this.permissionsLoading = true
      this.$http.post('/api/role/permissions',
        { role_id: this.role_id, permissions: this.selectedPermissions })
        .then(res => {
          this.showSuccess('Assigned successfully')
          this.permissionsLoading = false
          const role = this.roles.find(r => r.id === this.role_id)
          role.permissions = this.selectedPermissions
          this.closePermissionModal()
        })
        .catch(err => {
          this.permissionsLoading = false
          this.showError('Failed to assign permissions')
        })
    },
    deleteRole(id) {
      this.$http.delete(`/api/role/roles/${id}`)
        .then(res => {
          this.showSuccess('Deleted successfully')
          this.closeRoleModal()
          this.roles = this.roles.filter(t => t.id !== res.data.id)
        })
        .catch(err => {
          this.showError('Failed to delete role')
        })
    },
  },
}
</script>
